import React from 'react';
import './Footer.css';

const Footer = props => {
   return (
      <footer className='Footer'>
         <a href='mailto:info@redpanda.events'>
            info@redpanda.events
         </a>
      </footer>
   )
}

export default Footer;