import React from 'react';
import routes from './routes';
import Nav from './Components/Navbar/Nav';
import Footer from './Components/Footer/Footer';
import './App.css';

const App = props => {
  return (
    <div className='App'>
      <Nav />
      {routes}
      <Footer />
    </div>
  )
}

export default App;
