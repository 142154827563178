import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Main from './Components/Main/Main';
import About from './Components/About/About';

export default (
   <Switch>
      <Route exact path = '/' component={Main} />
      <Route path='/about' component={About} />
   </Switch>
)