import React from 'react';
import '../../Main.css';

const Intro = props => {

   return(
      <section id='home'>
         <h1 id='title'>
            Making events more valuable<br/> for owners and attendees
         </h1>
         <p>
            The events industry misses out on hundreds of millions of dollars of annual value creation because it fails to properly leverage data. Red Panda enhances and harnesses the power of your data to enable better outcomes for live events. Our analytics platform drives better engagement by enhancing attendee networking and stakeholder and sponsor targeting. Red Panda helps event owners create meaningful engagement, gather and analyze participant data, and drives predictive insights enabling confidence to scale.
         </p>
         <img src='keyboard_arrow_down-white-18dp.svg' alt='scroll down arrow'/>
      </section>
   )
}

export default Intro;