import React, { useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { withRouter } from 'react-router-dom';
import Announcement from './Announcement';
import './Nav.css';

const Nav = props => {
   const [showAnnouncement, setShowAnnouncement] = useState(true);

   const scrollToTop = () => {
      scroll.scrollToTop();
   };

   const scrollToBottom = () => {
      scroll.scrollToBottom();
   }

   const toggleShowAnnouncement = () => {
      setShowAnnouncement(!showAnnouncement);
   }

   console.log(props);
   return (
      <div className='Nav'>
         { showAnnouncement ? <Announcement toggle={toggleShowAnnouncement}/> : null}
         <nav className='navbar'>
            <div 
               className='branding' 
               onClick={() => {
                  scrollToTop();
                  props.history.push('/');
            }}>
               <img id="logo" src='RP_PNG_300.png' alt='red panda logo'/>
               <a onClick={scrollToTop}>Red Panda</a>
            </div>
            <ul className='nav-items'>
               <li>
                  <button id='contact-button'>
                     <a
                        href='#' 
                        className='link' 
                        onClick={async() => {
                           await scrollToBottom();
                           setTimeout(() => scrollToBottom(), 500);
                        }}
                     >
                        {'Contact'}
                     </a>
                  </button>
               </li>
            </ul>
         </nav>
      </div>
   )
}

export default withRouter(Nav);