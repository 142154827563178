import React from 'react';
import './About.css'

const About = props => {
   return(
      <main className='about'>
         <section id='about'>
            <h1 id='title' className='title'>
               Response to COVID-19
            </h1>
            <p>
               The tragic COVID-19 pandemic impacted the events industry in ways we could not have imagined just a few months ago. The dynamic situation surrounding the pandemic, and how it will ultimately impact live/in-person meetings and events remains unknown, but it is clear that the industry will never be the same.
            </p>
            <p>
               At Red Panda, we are dedicated to the understanding that live/in-person meetings and events are a vital element of business, and we are focused on innovation that will enable changes in the way these events are planned and operated to make them safe and more engaging for all stakeholders.
            </p>
            <p>
               Our company is rethinking the entire lifecycle for live-in-person events, from pre health and safety planning, mobile/virtual registration and check-in, analytics based personal networking, and predictive business opportunity matchmaking. 
            </p>
            <p>
               We support, and are excited for the return of safe and even more effective in-person events and are working hard to give the industry new tools for a fresh start. Stay tuned for more details and announcements in the near future about our platform.
            </p>
         </section>
      </main>
   )
}

export default About;