import React from 'react';
import { withRouter } from 'react-router-dom';

const Announcement = props => {

   const toggle = () => props.toggle();

   return (
      <React.Fragment>
         { props.location.pathname === '/about' 
            ? null
            : <>
               <div className='announcement'>
                  <div className='announcement-container'>
                     <span
                        onClick={() => props.history.push('/about')}
                        style={{paddingBottom: '0px'}}
                     >
                        Learn about how COVID-19 is affecting events
                     </span>
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width='1rem' height='1rem' onClick={toggle}><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                  </div>
               </div>
            </>
         }
      </React.Fragment>
   )
}

export default withRouter(Announcement);