import React from 'react';
import '../../Main.css';

const Product = props => {
   return( 
      <section id='product'>
         <div className='grid'>
            <div className='card' id='card1'>
               <img src='user-friends-solid.svg' alt=''/>
               <h2>
                  Red Panda Engage
               </h2>
               <p>
                  A live event app that allows participants to network and engage with speakers and sponsors during and after events.
               </p>
            </div>
            <div className='card' id='card2'>
               <img src='chart-bar-solid.svg' alt='data chart'/>
               <h2>
                  Data Analytics
               </h2>
               <p>
                  Leverages data across to model your events to predict outcomes and business cases. 
               </p>
            </div>
            <div className='card' id='card3'>
               <img src='bullhorn-solid.svg' alt='bullhorn'/>
               <h2>
                  Promotion
               </h2>
               <p>
                  An A.I. module that matches users and opportunities to enable personalized campaigns.
               </p>
            </div>
            <div className='card' id='card4'>
               <img src='calendar-check-solid.svg' alt='calendar'/>
               <h2>
                  Management Software
               </h2>
               <p>
                  Registration, badging, payments, scheduling, collaboration and logistics.
               </p>
            </div>
         </div>
      </section>
   )
}

export default Product;