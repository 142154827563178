import React, { useState } from 'react';
import '../../Main.css';
import axios from 'axios';

const Contact = props => {
   const [name, setName] = useState(''),
         [emailAddress, setEmailAddress] = useState(''),
         [subject, setSubject] = useState(''),
         [company, setCompany] = useState(''),
         [message, setMessage] = useState('');

   const handleSubmitEmail = () => {
      axios.post('/api/send-email', {
         name,
         email: emailAddress,
         company,
         subject,
         message,
      })
      .then(() => {
         console.log('email sent')
      })
      .catch(err => console.log(err));
      setName('');
      setEmailAddress('');
      setSubject('');
      setCompany('');
      setMessage('');
   }

   return (
      <section id='contact'>
         <h1 id='title'>
            Contact us
         </h1>
         <div className='form-container'>
            <div className='form-grid'>
               <div className='field-card' id='card1'>
                  <div className='field'>
                     <input type='text' name='name' className='input' placeholder=' ' value={name} onChange={e => setName(e.target.value)} />
                     <label className='label'>Name</label>
                  </div>
                  <div className='field'>
                     <input type='text' name='company' className='input' placeholder=' ' value={company} onChange={e => setCompany(e.target.value)} />
                     <label className='label'>Company</label>
                  </div>
                  <div className='field'>
                     <input type='email' name='email' className='input email-input' placeholder=' ' value={emailAddress} onChange={e => setEmailAddress(e.target.value)} />
                     <label className='label'>Email</label>
                  </div>
               </div>
               <div className='field-card' id='card2'>
                  <div className='field'>
                     <input type='text' name='subject' className='input' placeholder=' ' value={subject} onChange={e => setSubject(e.target.value)} />
                     <label className='label'>Subject</label>
                  </div>
                  <div className='field'>
                     <textarea type='text' name='message' className='input multi-line-input' value={message} placeholder=' ' rows='4' onChange={e => setMessage(e.target.value)} />
                     <label className='label multi-line-label'>Message</label>
                  </div>
               </div>
            </div>
         </div>
            <button onClick={handleSubmitEmail}>
               <img src='paper-plane-solid.svg' alt='send' />
               Send
            </button>
      </section>
   )
}

export default Contact;