import React from 'react';
import Intro from './Views/Intro/Intro';
import Product from './Views/Product/Product';
import Contact from './Views/Contact/Contact';
import './Main.css';

const Main = props => {
   return(
      <main className='main'>
         <Intro 
            id="section1"
         />
         <Product 
            id='section2'
         />
         <Contact 
            id='section3'
         />
      </main>
   )
}

export default Main;